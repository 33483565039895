<template>
    <div class="page" id="reorganize">
        <el-form class="query-form searchForm" size="small"
                 @keyup.enter.native="getDataList(1,1)"
                 ref="inputForm" :model="inputForm" label-width="80px">
            <el-form-item label="专家姓名" prop="repairExpert">
                <el-input v-model.trim="inputForm.repairExpert"
                          placeholder="请输入专家姓名(限50字)"
                          maxlength="50" clearable></el-input>
            </el-form-item>
            <el-form-item label="修复机构" prop="repairInstitution">
                <el-input v-model.trim="inputForm.repairInstitution"
                          placeholder="请输入修复机构(限50字)"
                          maxlength="50" clearable></el-input>
            </el-form-item>
            <el-form-item label="修复事项" prop="repairMatter">
                <el-input v-model.trim="inputForm.repairMatter"
                          placeholder="请输入修复事项(限50字)"
                          maxlength="50" clearable></el-input>
            </el-form-item>
            <el-form-item label="修复类型" prop="repairTypes">
                <el-select
                        v-model="inputForm.repairTypes"
                        clearable
                        placeholder="请选择修复类型"
                        style="width: 100%"
                >
                    <el-option
                            v-for="item in this.$dictUtils.getDictList('repair_types')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="修复时间" prop="appraisalDate" class="dateBox">
                <el-date-picker
                        v-model="inputForm.appraisalDate"
                        type="daterange"
                        style="width: 100%;"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label-width="20px" class="dateBox2">
                <div class="flex_b_c" style="flex-wrap: wrap">
                    <div>
                        <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">查询</el-button>
                        <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置</el-button>
                    </div>
                    <div>
                        <el-button type="primary" size="small"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:add`)"
                                   icon='el-icon-plus' @click="addData(0)">新增
                        </el-button>
                        <el-button type="primary" icon="el-icon-circle-check" size="small"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:batchAdopt`)"
                                   @click="through(1,3)">
                            批量通过
                        </el-button>
                        <el-button type="danger" icon="el-icon-circle-close" size="small"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:batchReject`)"
                                   @click="through(1,2)">
                            批量驳回
                        </el-button>
                    </div>
                </div>
            </el-form-item>
        </el-form>

        <div class="bg-white">
            <div class="flex_b_c">
                <div class="tabsCls" v-if="typePage == 'repairManage'">
                    <el-tabs size="small" v-model="selectTab" @tab-click="handleClick">
                        <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.name"
                                     :name="item.value"></el-tab-pane>
                    </el-tabs>
                </div>
                <div></div>
                <el-button size="small" v-show="hasPermissionButton(`collection:business:${typePage}:batchExpor`)"
                           @click="exportData(1)">
                    <i class="icon-piliangdaochu iconfont buIcon"/>
                    批量导出
                </el-button>
            </div>
            <el-table
                    :data="dataList"
                    size="small"
                    v-loading="loading"
                    ref="multipleTable"
                    :height="typePage == 'identifyRegister' ? 'calc(100vh - 380px)' : 'calc(100vh - 325px)'"
                    class="table"
                    :row-key="'id'"
                    :header-cell-style="{background:'#F9DFDF'}"
                    @selection-change="selectionChangeHandle"
            >
                <el-table-column :reserve-selection="true" type="selection" width="50"/>
                <el-table-column label="序号" type="index" width="50"></el-table-column>
                <el-table-column prop="repairState" label="审核状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ $dictUtils.getDictLabel("process_state", scope.row.repairState, '-') }}
                    </template>
                </el-table-column>
                <el-table-column prop="repairMatter" label="修复事项" show-overflow-tooltip></el-table-column>
                <el-table-column prop="repairStartTime" label="修复时间" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.repairStartTime | formatDate('YYYY-MM-DD') }}
                    </template>
                </el-table-column>
                <el-table-column prop="repairInstitution" label="修复机构" show-overflow-tooltip></el-table-column>
                <el-table-column prop="repairExpert" label="专家姓名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="repairHandled" label="经手人" show-overflow-tooltip></el-table-column>
                <el-table-column prop="repairTypes" label="修复类型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ $dictUtils.getDictLabel("repair_types", scope.row.repairTypes, '-') }}
                    </template>
                </el-table-column>
                <el-table-column prop="createUserName" label="创建人" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createDate" label="创建时间" width="150px">
                    <template slot-scope="scope">
                        {{ scope.row.createDate | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="updateUserName" label="最后更新人"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="updateDate" label="最后更新时间" width="150px"
                                 show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.updateDate | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="addData(1, scope.row,scope.$index)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:detail`)">
                            详情
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="submitTo(scope.row)"
                                   v-if="scope.row.repairState == 0"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:submit`)">
                            提交
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="submitTo(scope.row)"
                                   v-if="scope.row.repairState == '2'"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:resubmit`)">
                            重新提交
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="addData(2, scope.row)"
                                   v-if="scope.row.repairState != '1'"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:edit`)">
                            修改
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="record(scope.row)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:record`)">
                            审核记录
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="deleteDate(scope.row)"
                                   v-if="scope.row.repairState != '1'"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:delete`)">
                            删除
                        </el-button>

                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="through(2,3,scope.row)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:adopt`)">
                            通过
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="through(2,2,scope.row)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:reject`)">
                            驳回
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="text_center">
                <el-pagination
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="current"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="size"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                >
                </el-pagination>
            </div>
        </div>
        <record ref="record"></record>
        <reviewSuggestions ref="suggestions" @updataStatus="getDataList('',1)"></reviewSuggestions>
    </div>
</template>

<script>
import record from "@/views/modules/collection/accounts/module/record.vue";
import reviewSuggestions from "@/views/modules/collection/accounts/module/reviewSuggestions.vue";

export default {
    name: "repairManage",
    components: {reviewSuggestions, record},
    props: {
        typePage: {
            type: String,
            default: 'repairManage',
        },
    },
    data() {
        return {
            selectTab: '99',
            tabsList: [
                {
                    name: '全部',
                    value: '99',
                },
                {
                    name: '审核中',
                    value: '1',
                },
                {
                    name: '审核驳回',
                    value: '2',
                },
                {
                    name: '草稿',
                    value: '0',
                },
            ],
            inputForm: {
                repairExpert: '',
                repairInstitution: '',
                repairMatter: '',
                repairTypes: '',
                appraisalDate: [],
                startTime: '',
                endTime: '',
            },

            searchRecord: {},
            dataListSelect: [],
            loading: false,
            dataList: [],

            pageNo2: 0,
            current: 1,
            size: 10,
            total: 0,
        }
    },

    mounted() {
        let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
        if (listSearch) {
            this.inputForm = listSearch.inputForm
            this.pageNo2 = listSearch.current
            this.size = listSearch.size
            this.selectTab = listSearch.selectTab
        }
        if (this.typePage == 'repairRecord') {
            this.selectTab = 3
        }
        this.getDataList('', 1)
    },

    methods: {
        getDataList(type, dividePage) {
            if (type == 1) {
                this.current = 1
            }
            if (dividePage) {
                this.$refs.multipleTable.clearSelection()
            }
            this.loading = true
            if (this.inputForm.appraisalDate && this.inputForm.appraisalDate.length != 0) {
                this.inputForm.startTime = this.inputForm.appraisalDate[0]
                this.inputForm.endTime = this.inputForm.appraisalDate[1]
            } else {
                this.inputForm.startTime = ''
                this.inputForm.endTime = ''
            }
            let examineState = this.selectTab
            if (this.selectTab == 99) {
                examineState = ''
            }
            this.searchRecord = {
                ...this.inputForm,
                examineState: examineState,
                current: this.pageNo2 ? this.pageNo2 : this.current,
                size: this.size,
            }
            let fechUrl = this.api.collection.collectionrepairList
            if (this.typePage == 'repairAuditing') {
                fechUrl = this.api.collection.collectionrepairReviewList
            }
            this.$axios(fechUrl, this.searchRecord, 'get').then((res) => {
                if (res.status) {
                    this.dataList = res.data.records
                    this.total = parseInt(res.data.total)
                    let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
                    if (listSearch) {
                        this.current = listSearch.current
                        this.pageNo2 = 0
                        sessionStorage.removeItem('listSearch')
                    }
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout()
                    })
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
            })
        },

        //提交
        submitTo(row) {
            this.$confirm(`您是否确认提交数据?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios(this.api.collection.collectionrepairSubmit + row.id, {}, 'put').then(data => {
                    if (data && data.status) {
                        this.$message.success('提交成功')
                        this.getDataList('', 1)
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            })
        },

        //num 0新增 1详情 2修改、重新提交 4审核
        addData(num, row, index) {
            if (num == 0) {
                this.$router.push({
                    path: '/collection/business/repair/addRepair',
                    query: {butType: num},
                })
            } else {
                if (this.typePage == 'repairRecord') {
                    this.$router.push({
                        path: '/collection/business/repair/recordDetail',
                        query: {butType: num, id: row.id},
                    })
                } else {
                    let listSearch = {
                        inputForm: this.inputForm,
                        selectTab: this.selectTab,
                        current: this.current,
                        size: this.size
                    }
                    sessionStorage.setItem('listSearch', JSON.stringify(listSearch))
                    this.$set(this.searchRecord, 'viewCurrent', (this.current - 1) * this.size + index + 1)
                    sessionStorage.setItem('registrationSearch', JSON.stringify(this.searchRecord))
                    if (this.typePage == 'repairAuditing') {
                        num = 4
                    }
                    this.$router.push({
                        path: '/collection/business/repair/addRepair',
                        query: {butType: num, id: row.id},
                    })
                }
            }
        },

        //审核记录
        record(row) {
            this.$refs.record.init(row.id, '',7)
        },

        //num 1多个 2单个  type 2驳回 3通过
        through(num, type, row) {
            let setData = []
            let revdId
            if (num == 1) {
                if (this.dataListSelect.length == 0) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                setData = this.dataListSelect
            } else {
                setData = [row]
            }
            this.$refs.suggestions.init(num, setData, type, revdId, this.api.collection.collectionrepairExamine)
        },

        deleteDate(row) {
            this.$confirm(`您是否确认删除数据?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios(this.api.collection.collectionrepairRemove + row.id, {}, 'delete').then(data => {
                    if (data && data.status) {
                        this.$message.success('删除成功')
                        this.getDataList('', 1)
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            })
        },

        resetting() {
            this.$refs.inputForm.resetFields();
            this.getDataList(1, 1)
        },

        exportData() {
            let ids = []
            if (this.dataListSelect.length == 0) {
                this.$message.warning('请至少选择一条数据')
                return
            }

            ids = this.dataListSelect.map(item => {
                if (item) {
                    return item.id
                }
            })
            let data = {
                ids: ids,
            }
            this.exportExcel(this.api.collection.collectionrepairExport, data, '修复登记列表', 'post')
            this.getDataList('', 1)
        },

        setAge(age) {
            this.inputForm[age] = this.inputForm[age].replace(/[^0-9.]/g, '')
            if (this.inputForm[age] > 200) {
                this.inputForm[age] = 200
            }
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },

        handleClick(tab, event) {
            this.getDataList(1, 1)
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.size = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.current = val;
            this.getDataList('', 2);
        },
    }
    ,
}
</script>

<style lang="scss" scoped>
.bg-white {
    overflow-y: auto !important;
}

/*去掉tabs底部的下划线*/
.tabsCls >>> .el-tabs__nav-wrap::after {
    position: static !important;
}

.searchForm {
    .el-form-item--small.el-form-item {
        display: inline-block;
        width: 24.5%;
    }

    .dateBox.el-form-item--small.el-form-item {
        display: inline-block;
        width: 38%;
    }

    .dateBox2.el-form-item--small.el-form-item {
        display: inline-block;
        width: 60%;
    }
}
</style>